export const BILLINGS_PERMISSIONS = {
  readGlobalBilling: { module: 'Billing', action: 'GetGlobalBillingByFilter' },
  timeSheetWithHistoryById: {
    module: 'Billing',
    action: 'GetTimeSheetWithHistoryById',
  },
  updateConsolidatedBillingStatus: {
    module: 'Billing',
    action: 'UpdateConsolidatedBillingStatus',
  },
  updateConsolidatedBillingHours: {
    module: 'Billing',
    action: 'UpdateConsolidatedBillingHours',
  },
  updateConsolidatedBillingCostAdjustment: {
    module: 'Billing',
    action: 'UpdateConsolidatedBillingCostAdjustment',
  },
  updateConsolidatedBillingDiscrepancy: {
    module: 'Billing',
    action: 'UpdateConsolidatedBillingDiscrepancy',
  },
  resetConsolidatedBillingDiscrepancy: {
    module: 'Billing',
    action: 'ResetConsolidatedBillingDiscrepancy',
  },
};
