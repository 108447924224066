export const KANDA_TIME_SHEET_PERMISSIONS = {
  updateHours: {
    module: 'KandaTimeSheet',
    action: 'UpdateKandaTimeSheetHours',
  },
  readPersonalTimeSheets: {
    module: 'KandaTimeSheet',
    action: 'GetPersonalTimeSheets',
  },
};
