export const ACCOUNTS_PERMISSIONS = {
  read: { module: 'Account', action: 'GetAllAccounts' },
  readByFilter: { module: 'Account', action: 'GetAccountsByFilter' },
  readById: { module: 'Account', action: 'GetAccountById' },
  update: { module: 'Account', action: 'UpdateAccount' },
  create: { module: 'Account', action: 'AddAccount' },
  delete: { module: 'Account', action: 'DeleteAccount' },
  readChecklist: {
    module: 'Account',
    action: 'GetAccountOnboardingChecklists',
  },
  addChecklist: {
    module: 'Account',
    action: 'AddOrUpdateAccountOnboardingCheck',
  },
  deleteChecklist: {
    module: 'Account',
    action: 'DeleteAccountOnboardingCheck',
  },
  activateDeactivateAccount: {
    module: 'Account',
    action: 'ActivateDeactivateAccount',
  },
  changeAccountHealth: { module: 'Account', action: 'ChangeAccountHealth' },
  changeAccountMsaSigned: {
    module: 'Account',
    action: 'ChangeAccountMsaSigned',
  },
  changeAccountSendInvoiceDetails: {
    module: 'Account',
    action: 'ChangeAccountSendInvoiceDetails',
  },
  readVacations: { module: 'Account', action: 'GetAccountResourceVacations' },
  updateVacationDaysOff: { module: 'Account', action: 'UpdateVacationDaysOff' },
  uploadAccountDocuments: {
    module: 'Account',
    action: 'UploadDocumentsAccount',
  },
  downloadAccountDocument: {
    module: 'Account',
    action: 'GetDownloadAccountDocument',
  },
  getAccountDiscounts: {
    module: 'Account',
    action: 'GetAccountDiscounts',
  },
  updateAccountDiscount: {
    module: 'Account',
    action: 'UpdateAccountDiscount',
  },
  addAccountDiscount: {
    module: 'Account',
    action: 'AddAccountDiscount',
  },
};
