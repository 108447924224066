export const INVOICES_PERMISSIONS = {
  readByFilter: { module: 'Invoice', action: 'GetInvoicesByFilter' },
  readById: { module: 'Invoice', action: 'GetInvoiceById' },
  updateStatus: { module: 'Invoice', action: 'UpdateInvoiceStatus' },
  updateDetails: { module: 'Invoice', action: 'UpdateInvoiceDetails' },
  updateAdjustment: { module: 'Invoice', action: 'UpdateDiscrepancy' },
  exportInvoiceCSV: { module: 'Invoice', action: 'ExportInvoiceCSV' },
  exportToSalesForce: { module: 'Invoice', action: 'ExportToSalesForce' },
  exportMarginToSalesForce: {
    module: 'Invoice',
    action: 'ExportMarginToSalesForce',
  },
  updateCostCorrection: { module: 'Invoice', action: 'UpdateCostCorrection' },
  exportAllInvoicesCSV: { module: 'Invoice', action: 'ExportAllInvoicesCSV' },
  exportShortInvoices: { module: 'Invoice', action: 'ExportShortInvoices' },
};
