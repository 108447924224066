export const USER_PERMISSIONS = {
  read: { module: 'User', action: 'AllUsers' },
  readById: { module: 'User', action: 'GetUserById' },
  create: { module: 'User', action: 'RegisterUser' },
  update: { module: 'User', action: 'UpdateUser' },
  deactivate: { module: 'User', action: 'DeactivateUser' },
  activate: { module: 'User', action: 'ActivateUser' },
  resetPassword: { module: 'User', action: 'ResetUserPassword' },
  updateUserPermissions: {
    module: 'User',
    action: 'UpdateUserPermissions',
  },
  setOrUnsetUserToEmployeeAndVendor: {
    module: 'User',
    action: 'SetOrUnsetUserToEmployeeAndVendor',
  },
  unattachUserFromEmployee: {
    module: 'User',
    action: 'UnattachUserFromEmployee',
  },
  changeRole: { module: 'User', action: 'ChangeRole' },
  dropManualPermissions: { module: 'User', action: 'DropManualPermissions' },
  createUserByResource: { module: 'User', action: 'CreateUserByResourceId' },
};
