export const EMPLOYEES_PERMISSIONS = {
  read: { module: 'Employee', action: 'GetAllEmployees' },
  readByFilter: { module: 'Employee', action: 'GetEmployeesByFilter' },
  readById: { module: 'Employee', action: 'GetEmployeeById' },
  update: { module: 'Employee', action: 'UpdateEmployee' },
  create: { module: 'Employee', action: 'AddEmployee' },
  delete: { module: 'Employee', action: 'DeleteEmployee' },
  getEmployeeCVById: { module: 'Employee', action: 'GetEmployeeCVById' },
  getEmployeeComments: { module: 'Employee', action: 'GetEmployeeComments' },
  createOrUpdateCosts: {
    module: 'Employee',
    action: 'AddOrUpdateResourceCosts',
  },
  readCosts: { module: 'Employee', action: 'GetResourceCostsByResourceId' },
  changeEmployeeState: { module: 'Employee', action: 'ChangeEmployeeState' },
  readPersonalProfile: { module: 'Employee', action: 'GetPersonalProfile' },
  addEmployeeComment: { module: 'Employee', action: 'AddEmployeeComment' },
  readRecruitmentFees: {
    module: 'Employee',
    action: 'GetResourceRecruitmentFees',
  },
  generateRecruitmentFees: {
    module: 'Employee',
    action: 'GenerateResourceRecruitmentFee',
  },
  addRecruitmentFees: {
    module: 'Employee',
    action: 'AddResourceRecruitmentFee',
  },
  updateRecruitmentFees: {
    module: 'Employee',
    action: 'UpdateResourceRecruitmentFee',
  },
  deleteRecruitmentFees: {
    module: 'Employee',
    action: 'DeleteResourceRecruitmentFee',
  },
  getRecruitmentFeeInvoiceById: {
    module: 'Employee',
    action: 'GetRecruitmentFeeInvoiceById',
  },
  updateRecruitmentFeeStatus: {
    module: 'Employee',
    action: 'UpdateResourceRecruitmentFeeStatus',
  },
  readSalaryNotes: {
    module: 'Employee',
    action: 'GetResourceSalaryNotes',
  },
  addSalaryNotes: {
    module: 'Employee',
    action: 'AddResourceSalaryNote',
  },
  updateSalaryNotes: {
    module: 'Employee',
    action: 'UpdateResourceSalaryNote',
  },
  deleteSalaryNotes: {
    module: 'Employee',
    action: 'DeleteResourceSalaryNote',
  },
};
