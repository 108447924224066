import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { inject, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/legacy-dialog';
import { MAT_LEGACY_MENU_DEFAULT_OPTIONS as MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import {
  BrowserModule,
  REMOVE_STYLES_ON_COMPONENT_DESTROY,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TitleStrategy } from '@angular/router';
import nonInterceptedReqChecker from '@app/core/nonInterceptedReqChecker';
import { RefreshTokenGtopService } from '@app/core/services/refresh-token.service';
import ReportIssueGtopService from '@app/core/services/report-issue-gtop.service';
import { routes } from '@app/shared/const';
import { I18nModule, I18nService } from '@common/components/i18n';
import { SpinnerService } from '@common/components/spinner/spinner.service';
import { WrapperModule } from '@common/components/wrapper/wrapper.module';
import { I18N_CONFIG } from '@common/const';
import { DEFAULT_SPINNER_CONFIG } from '@common/const/apollo.const';
import {
  BASE_URL,
  ENVIRONMENT,
  NAVIGATION_ROUTES,
  NON_INTERCEPTED_REQUEST_CHECKER,
} from '@common/const/common';
import { config } from '@common/const/dialog.const';
import { quillModules } from '@common/const/text-editor-config.const';
import { AnchorAppLayoutDirective } from '@common/directives/anchor-app-layout.directive';
import { AuthInterceptor } from '@common/interceptors/auth.interceptor';
import { AuthorizeErrorWithRelogin } from '@common/interceptors/authorize-error-with-relogin.interceptor';
import { CoreModule, MaterialModule } from '@common/modules';
import { AuthenticationService } from '@common/services/authentication.service';
import { ConfigService } from '@common/services/core/config.service';
import { DialogService } from '@common/services/dialog.service';
import { MatPaginatorI18nService } from '@common/services/mat-paginator-i18n.service';
import { RefreshTokenService } from '@common/services/refresh-token.service';
import ReportIssueService from '@common/services/report-issue.service';
import { CustomTitleStrategy } from '@common/services/title-strategy.service';
import { SharedModule } from '@common/shared.module';
import { provideTippyConfig } from '@ngneat/helipopper';
import { environment } from '@root/environments/environment';
import { QuillConfigModule, QuillModule } from 'ngx-quill';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PowerBIEmbedModule } from 'powerbi-client-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationGtopService } from './core/services/authentication.service';
import { ConfigGtopService } from './core/services/config.service';
import { GraphQLModule } from './graphql.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    GraphQLModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    SharedModule,
    CoreModule,
    MaterialModule,
    BrowserAnimationsModule,
    WrapperModule,
    I18nModule.forRoot(I18N_CONFIG),
    NgxSpinnerModule.forRoot({ ...DEFAULT_SPINNER_CONFIG }),
    PowerBIEmbedModule,
    QuillModule.forRoot(),
    QuillConfigModule.forRoot({
      modules: {
        syntax: false,
        toolbar: quillModules.toolbar,
      },
      bounds: 'quill-editor',
    }),
    AnchorAppLayoutDirective,
  ],
  providers: [
    SpinnerService,
    {
      provide: RefreshTokenService,
      useClass: RefreshTokenGtopService,
    },
    {
      provide: AuthenticationService,
      useClass: AuthenticationGtopService,
    },
    {
      provide: ConfigService,
      useClass: ConfigGtopService,
    },
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: BASE_URL,
      useValue: environment.apiURL,
    },
    {
      provide: NAVIGATION_ROUTES,
      useValue: routes,
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorI18nService,
    },
    {
      provide: ReportIssueService,
      useClass: ReportIssueGtopService,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '854389122288-crdtnvgj41tcdimpqsma0gglqme5i5rg.apps.googleusercontent.com',
              { oneTapEnabled: false },
            ),
          },
        ],
        onError: (err: any) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    // AppInitService,
    // { provide: APP_INITIALIZER,useFactory: initializeApp, deps: [AppInitService], multi: true}

    /* TODO TASK 84293
     Token refresh is currently implemented in Apollo Http Link.
     The reason is that in the http interceptor, when refreshing a token,
     only the request that initializes the request for new tokens propagates the response to the view.
     The rest of the requests succeed, but their data is not passed to the view.
     After all, the user interface is inconsistent.

    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    }, */
    {
      provide: NON_INTERCEPTED_REQUEST_CHECKER,
      useValue: nonInterceptedReqChecker,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizeErrorWithRelogin,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { ...config } },
    { provide: MAT_MENU_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    { provide: TitleStrategy, useClass: CustomTitleStrategy },
    { provide: REMOVE_STYLES_ON_COMPONENT_DESTROY, useValue: true },
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: {
          theme: 'light',
          maxWidth: 1000,
        },
      },
    }),
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {
  constructor() {
    inject(I18nService).initLang();
    inject(DialogService).init();
  }
}
