import { DASHBOARD_PERMISSIONS } from '@app/modules/dashboard/models/dashboard.const';
import { ACCOUNTS_PERMISSIONS } from '@app/modules/accounts/models/accounts.const';
import { POSITIONS_PERMISSIONS } from '@app/modules/positions/models/positions.const';
import { CANDIDATES_PERMISSIONS } from '@app/modules/candidates/models/candidates.const';
import { REQUISITIONS_PERMISSIONS } from '@app/modules/requisitions/models/requisitions.const';
import { INTERVIEWS_PERMISSIONS } from '@app/modules/interviews/models/interviews.const';
import { BILLINGS_PERMISSIONS } from '@app/modules/finance/global-billing/models/billings.const';
import { INVOICES_PERMISSIONS } from '@app/modules/finance/invoices/models/invoices.const';
import { RATE_CARDS_PERMISSIONS } from '@app/modules/finance/rate-cards/models/rate-cards.const';
import { ROLES_PERMISSIONS } from '@app/modules/admin/models/roles.const';
import { HISTORY_LOGS_PERMISSIONS } from '@app/shared/components/history-logs/models/history-logs.const';
import { PARTNER_BILL_PERMISSIONS } from '@app/modules/finance/accounting-pages/models/partners-bills.const';
import { USER_PERMISSIONS } from '@app/modules/admin/models/users.const';
import { EXPENSES_PERMISSIONS } from '@app/modules/kanda-ca/expenses/expenses.const';
import { KEY_PERFORMANCE_INDICATORS_PERMISSIONS } from '@app/modules/kanda-ca/key-performance-indicators/models/key-performance-indicators.const';
import { TIME_SHEET_PERMISSIONS } from '@app/modules/finance/time-sheets/models/time-sheets.const';
import { ACCOUNT_RECEIVABLES_PERMISSIONS } from '@app/modules/finance/account-receivables/models/account-receivables.const';
import { COMMISSION_FEES_PERMISSIONS } from '@app/modules/finance/commission-fees/models/commission-fees.const';
import { OTHER_BILLS_PERMISSIONS } from '@app/modules/finance/other-bills/models/other-bills.const';
import { COST_EXCEPTIONS_PERMISSIONS } from '@app/modules/kanda-ca/cost-exceptions/cost-exceptions.const';
import { EMPLOYEES_PERMISSIONS } from '@app/modules/employees/models/employees.const';
import { PLACEMENTS_PERMISSIONS } from '@app/modules/placement/models/placements.const';
import { SALARY_SETTINGS_PERMISSIONS } from '@app/modules/kanda-ca/salary-settings/models/salary-settings.const';

export const DASHBOARD_PAGE_PERMISSIONS = [
  DASHBOARD_PERMISSIONS.readCurrentActivities,
  DASHBOARD_PERMISSIONS.readActiveStaffings,
  DASHBOARD_PERMISSIONS.readUtilizations,
  DASHBOARD_PERMISSIONS.readOnboardingPerformances,
];

export const STAFFING_BOARD_PAGE_PERMISSIONS = [
  ACCOUNTS_PERMISSIONS.readByFilter,
  POSITIONS_PERMISSIONS.readByFilter,
  CANDIDATES_PERMISSIONS.readByFilter,
  REQUISITIONS_PERMISSIONS.readByFilter,
  CANDIDATES_PERMISSIONS.readCandidatesWithColorByFilter,
  POSITIONS_PERMISSIONS.readById,
];

export const ONBOARDING_PAGE_PERMISSIONS = [
  STAFFING_BOARD_PAGE_PERMISSIONS,
  CANDIDATES_PERMISSIONS.readByFilter,
  REQUISITIONS_PERMISSIONS.readByFilter,
  POSITIONS_PERMISSIONS.readByFilter,
  INTERVIEWS_PERMISSIONS.readByFilter,
];

export const FINANCE_ACCOUNTING_PAGES_PERMISSIONS = [
  PARTNER_BILL_PERMISSIONS.readByFilter,
  OTHER_BILLS_PERMISSIONS.readByFilter,
];

export const FINANCE_PERMISSIONS = [
  RATE_CARDS_PERMISSIONS.read,
  TIME_SHEET_PERMISSIONS.getTimeSheetsAllByFilter,
  BILLINGS_PERMISSIONS.readGlobalBilling,
  INVOICES_PERMISSIONS.readByFilter,
  ACCOUNT_RECEIVABLES_PERMISSIONS.readByFilter,
  COMMISSION_FEES_PERMISSIONS.readByFilter,
  PARTNER_BILL_PERMISSIONS.readByFilter,
  OTHER_BILLS_PERMISSIONS.readByFilter,
];

export const SETTINGS_PAGE_PERMISSIONS = [
  USER_PERMISSIONS.read,
  ROLES_PERMISSIONS.read,
  HISTORY_LOGS_PERMISSIONS.readByFilter,
];

export const KANDA_CA_PERMISSIONS = [
  EXPENSES_PERMISSIONS.readByFilter,
  COST_EXCEPTIONS_PERMISSIONS.readByFilter,
  KEY_PERFORMANCE_INDICATORS_PERMISSIONS.read,
  EMPLOYEES_PERMISSIONS.readByFilter,
  PLACEMENTS_PERMISSIONS.readByFilter,
  SALARY_SETTINGS_PERMISSIONS.read,
];
