import { IRoute } from '@common/models';
import { REPORTS_PERMISSIONS } from '@app/modules/reports/models/reports.const';
import { ROLES_PERMISSIONS } from '@app/modules/admin/models/roles.const';
import { REQUISITIONS_PERMISSIONS } from '@app/modules/requisitions/models/requisitions.const';
import { POSITIONS_PERMISSIONS } from '@app/modules/positions/models/positions.const';
import { PLACEMENTS_PERMISSIONS } from '@app/modules/placement/models/placements.const';
import { ACCOUNTS_PERMISSIONS } from '@app/modules/accounts/models/accounts.const';
import { PROJECTS_PERMISSIONS } from '@app/modules/projects/models/projects.const';
import { VENDORS_PERMISSIONS } from '@app/modules/vendors/models/vendors.const';
import { CANDIDATES_PERMISSIONS } from '@app/modules/candidates/models/candidates.const';
import { HISTORY_LOGS_PERMISSIONS } from '@app/shared/components/history-logs/models/history-logs.const';
import { REFERENCES_PERMISSIONS } from '@app/modules/references/models/references.const';
import { INTERVIEWS_PERMISSIONS } from '@app/modules/interviews/models/interviews.const';
import { RATE_CARDS_PERMISSIONS } from '@app/modules/finance/rate-cards/models/rate-cards.const';
import {
  EnvironmentInjector,
  inject,
  runInInjectionContext,
} from '@angular/core';
import { AuthorizationService } from '@common/services/authorization.service';
import { RoleEnum } from '@common/generated/graphql';
import { EDIT, NEW } from '@common/const';
import { EMPLOYEES_PERMISSIONS } from '@app/modules/employees/models/employees.const';
import { TIME_SHEET_PERMISSIONS } from '@app/modules/finance/time-sheets/models/time-sheets.const';
import { PermissionsMode } from '@common/directives/permission-if.directive';
import {
  DASHBOARD_PAGE_PERMISSIONS,
  FINANCE_ACCOUNTING_PAGES_PERMISSIONS,
  FINANCE_PERMISSIONS,
  KANDA_CA_PERMISSIONS,
  ONBOARDING_PAGE_PERMISSIONS,
  SETTINGS_PAGE_PERMISSIONS,
  STAFFING_BOARD_PAGE_PERMISSIONS,
} from '@app/shared/const/page-permissions.const';
import { PARTNER_BILL_PERMISSIONS } from '@app/modules/finance/accounting-pages/models/partners-bills.const';
import { USER_PERMISSIONS } from '@app/modules/admin/models/users.const';
import { ACCOUNT_RECEIVABLES_PERMISSIONS } from '@app/modules/finance/account-receivables/models/account-receivables.const';
import { EXPENSES_PERMISSIONS } from '@app/modules/kanda-ca/expenses/expenses.const';
import { OTHER_BILLS_PERMISSIONS } from '@app/modules/finance/other-bills/models/other-bills.const';
import { COMMISSION_FEES_PERMISSIONS } from '@app/modules/finance/commission-fees/models/commission-fees.const';
import { KEY_PERFORMANCE_INDICATORS_PERMISSIONS } from '@app/modules/kanda-ca/key-performance-indicators/models/key-performance-indicators.const';
import { COST_EXCEPTIONS_PERMISSIONS } from '@app/modules/kanda-ca/cost-exceptions/cost-exceptions.const';
import { SALARY_SETTINGS_PERMISSIONS } from '@app/modules/kanda-ca/salary-settings/models/salary-settings.const';
import { BILLINGS_PERMISSIONS } from '@app/modules/finance/global-billing/models/billings.const';
import { KANDA_TIME_SHEET_PERMISSIONS } from '@app/modules/finance/kanda-time-sheets/models/kanda-time-sheets.const';
import { INVOICES_PERMISSIONS } from '@app/modules/finance/invoices/models/invoices.const';

export const REFERENCE_LIST = Object.freeze({
  root: 'reference-list',
  skills: 'skills',
  guilds: 'guilds',
  jobTitles: 'jobTitles',
  seniorities: 'seniorities',
  skillSets: 'skill-sets',
  levels: 'skill-levels',
  englishLevels: 'english-levels',
  positionNames: 'position-names',
  regions: 'rate-regions',
  geographicalRegions: 'regions',
  countries: 'countries',
  timeZones: 'time-zones',
  workStatuses: 'work-statuses',
  rejectionReasons: 'rejection-reasons',
  withdrawalReasons: 'withdrawal-reasons',
  onboardingChecklist: 'onboarding-checklists',
  industries: 'industries',
});

export const ONBOARDING = Object.freeze({
  root: 'onboarding',
  staffingBoard: 'staffing-board',
});

export const EMPLOYEES = Object.freeze({
  root: 'employees',
  employeeId: 'employeeId',
  new: NEW,
  edit: EDIT,
  jobLog: 'job-log',
  personalLog: 'personal-log',
  placements: 'placements',
  candidates: 'candidates',
  costs: 'costs',
  recruitmentFee: 'recruitment-fee',
  salaryNote: 'salary-note',
});

export const PERSONAL_PROFILE = Object.freeze({
  root: 'personal-profile',
  placements: 'placements',
  candidates: 'candidates',
});

export const DASHBOARD = Object.freeze({
  root: 'dashboard',
});

export const ACCOUNTS = Object.freeze({
  root: 'accounts',
  accountId: 'accountId',
  new: NEW,
  edit: EDIT,
  documents: 'documents',
  links: 'links',
  projects: 'projects',
  positions: 'positions',
  checklist: 'checklist',
  daysOff: 'days-off-counters',
  discounts: 'discounts',
});

export const PLACEMENTS = Object.freeze({
  root: 'placements',
  placementId: 'placementId',
  edit: EDIT,
  new: NEW,
  billingRates: 'billing-rates',
  checklist: 'checklist',
  move: 'move',
});

export const ACCOUNTS_INFO = Object.freeze({
  root: 'account-info',
  accounts: 'account-info',
});

export const VENDORS = Object.freeze({
  root: 'vendors',
  vendorId: 'vendorId',
  rateId: 'rateId',
  new: NEW,
  edit: EDIT,
  documents: 'documents',
  invoices: 'invoices',
  links: 'links',
  people: 'people',
  rateCards: 'rate-cards',
  reportNotes: 'report-notes',
});

export const VENDORS_INFO = Object.freeze({
  root: 'vendor-info',
  vendors: 'vendor-info',
});

export const PROJECTS = Object.freeze({
  root: 'projects',
  projectForm: 'project-form',
  projectInfo: 'project-info',
  projectId: 'projectId',
  new: NEW,
  edit: EDIT,
  documents: 'documents',
  links: 'links',
  positions: 'positions',
  placements: 'placements',
  requisitions: 'requisitions',
  candidates: 'candidates',
  requisitionId: 'requisitionId',
  discounts: 'discounts',
});

export const PROJECTS_INFO = Object.freeze({
  root: 'project-info',
});

export const POSITIONS = Object.freeze({
  root: 'positions',
  positionForm: 'position-form',
  positionInfo: 'position-info',
  positionId: 'positionId',
  new: NEW,
  edit: EDIT,
  requisitions: 'requisitions',
  placements: 'placements',
  requisitionId: 'requisitionId',
  candidates: 'candidates',
  notes: 'notes',
  availableResources: 'available',
});

export const REQUISITIONS = Object.freeze({
  root: 'requisitions',
  requisitionId: 'requisitionId',
  new: NEW,
  edit: EDIT,
  cv: 'cv',
  candidates: 'candidates',
  requisitionForm: 'requisition-form',
  notes: 'requisition-notes',
});

export const CANDIDATES = Object.freeze({
  root: 'candidates',
  candidateId: 'candidateId',
  new: NEW,
  edit: EDIT,
  interviews: 'interviews',
  history: 'history',
});

export const INTERVIEWS = Object.freeze({
  root: 'interviews',
  interviewId: 'interviewId',
});

export const REPORTS = Object.freeze({
  root: 'reports',
  powerBI: 'report-in-power-bi',
  reportKey: 'reportKey',
});

export const HISTORY_LOGS = Object.freeze({
  root: 'history-logs',
  historyLogId: 'historyLogId',
});

export const ADMIN = Object.freeze({
  root: 'admin-setting',
  roles: 'roles',
  users: 'users',
});

export const USERS = Object.freeze({
  root: 'users',
  userInfo: 'user-info',
  userId: 'userId',
  new: NEW,
  edit: EDIT,
  userAccessControl: 'user-access-control',
});

export const ROLES = Object.freeze({
  root: 'roles',
  roleInfo: 'role-info',
  roleId: 'roleId',
  new: NEW,
  roleAccessControl: 'access-control',
});

export const FINANCE = Object.freeze({
  root: 'finance',
  accountingPages: 'accounting-pages',
});

export const KANDA_CA = Object.freeze({
  root: 'kanda-ca',
});

export const VENDOR_RATE_CARDS = Object.freeze({
  rateCardId: 'vendorRateCardId',
  new: NEW,
  edit: EDIT,
});

export const RATE_CARDS = Object.freeze({
  root: 'rate-cards',
  rateCardId: 'rateCardId',
  new: NEW,
  edit: EDIT,
});

export const KANDA_CA_EXPENSES = Object.freeze({
  root: 'administrative-costs',
});

export const KANDA_CA_COST_EXCEPTIONS = Object.freeze({
  root: 'cost-exceptions',
});

export const KANDA_CA_KEY_PERFORMANCE_INDICATORS = Object.freeze({
  root: 'kanda-ca-kpis',
  edit: EDIT,
});

export const SALARY_SETTINGS = Object.freeze({
  root: 'salary-settings',
  edit: EDIT,
});

export const KANDA_TIME_SHEETS = Object.freeze({
  root: 'kanda-time-sheets',
});

export const TIME_SHEETS_ALL = Object.freeze({
  root: 'time-sheets-all',
});

export const BILLINGS = Object.freeze({
  root: 'billings',
});

export const PARTNERS_BILLS = Object.freeze({
  root: 'partners-bills',
  partnerBillId: 'partnerBillId',
  details: 'details',
  historyLogs: 'history-logs',
});

export const IC_BILLS = Object.freeze({
  root: 'ics-bills',
});

export const OTHER_BILLS = Object.freeze({
  root: 'other-bills',
});

export const PERSONAL_TIME_SHEETS = Object.freeze({
  root: 'time-sheet',
});

export const INVOICES = Object.freeze({
  root: 'invoices',
  invoiceId: 'invoiceId',
  details: 'details',
  historyLogs: 'history-logs',
});

export const ACCOUNT_RECEIVABLES = Object.freeze({
  root: 'account-receivables',
});

export const COMMISSION_FEES = Object.freeze({
  root: 'commission-fees',
});

export const GLOBAL_BILLING = Object.freeze({
  root: 'consolidated-billing',
  globalBillingId: 'consolidatedBillingId',
});

export const financeRoute: IRoute[] = [
  {
    path: RATE_CARDS.root,
    name: 'RATE_CARDS',
    disabled: false,
    permission: RATE_CARDS_PERMISSIONS.read,
  },
  {
    path: TIME_SHEETS_ALL.root,
    name: 'TIME_SHEETS_ALL',
    disabled: false,
    permission: TIME_SHEET_PERMISSIONS.getTimeSheetsAllByFilter,
  },
  {
    path: GLOBAL_BILLING.root,
    name: 'GLOBAL_BILLING',
    disabled: false,
    permission: BILLINGS_PERMISSIONS.readGlobalBilling,
  },
  {
    path: INVOICES.root,
    name: 'INVOICES',
    disabled: false,
    permission: INVOICES_PERMISSIONS.readByFilter,
  },
  {
    path: FINANCE.accountingPages,
    name: 'KANDA_AP',
    disabled: false,
    permission: FINANCE_ACCOUNTING_PAGES_PERMISSIONS,
    children: [
      {
        path: PARTNERS_BILLS.root,
        name: 'PARTNERS_BILLS',
        disabled: false,
        permission: PARTNER_BILL_PERMISSIONS.readByFilter,
      },
      {
        path: IC_BILLS.root,
        name: 'ICS_BILLS',
        disabled: false,
        permission: PARTNER_BILL_PERMISSIONS.readByFilter,
      },
      {
        path: OTHER_BILLS.root,
        name: 'OTHER_BILLS',
        disabled: false,
        permission: OTHER_BILLS_PERMISSIONS.readByFilter,
      },
    ],
  },
  {
    path: ACCOUNT_RECEIVABLES.root,
    name: 'DAILY_AR_AGING',
    disabled: false,
    permission: ACCOUNT_RECEIVABLES_PERMISSIONS.readByFilter,
    permissionsMode: PermissionsMode.Every,
  },
  {
    path: COMMISSION_FEES.root,
    name: 'COMMISSION_FEES',
    disabled: false,
    permission: COMMISSION_FEES_PERMISSIONS.readByFilter,
    permissionsMode: PermissionsMode.Every,
  },
];

export const kandaCARoute: IRoute[] = [
  {
    path: KANDA_CA_EXPENSES.root,
    name: 'ADMINISTRATIVE_COSTS',
    disabled: false,
    permission: EXPENSES_PERMISSIONS.readByFilter,
  },
  {
    path: KANDA_CA_COST_EXCEPTIONS.root,
    name: 'COST_EXCEPTIONS',
    disabled: false,
    permission: COST_EXCEPTIONS_PERMISSIONS.readByFilter,
  },
  {
    path: KANDA_CA_KEY_PERFORMANCE_INDICATORS.root,
    name: 'KANDA_CA_KPIS',
    disabled: false,
    permission: KEY_PERFORMANCE_INDICATORS_PERMISSIONS.read,
  },
  {
    path: EMPLOYEES.root,
    name: 'CA_RESOURCES',
    disabled: false,
    permission: EMPLOYEES_PERMISSIONS.readByFilter,
  },
  {
    path: PLACEMENTS.root,
    name: 'CA_PLACEMENTS',
    disabled: false,
    permission: PLACEMENTS_PERMISSIONS.readByFilter,
  },
  {
    path: SALARY_SETTINGS.root,
    name: 'SALARY_SETTINGS',
    disabled: false,
    permission: SALARY_SETTINGS_PERMISSIONS.read,
  },
];

export const onboardingRoute: IRoute[] = [
  {
    icon: 'work',
    path: ONBOARDING.staffingBoard,
    name: 'STAFFING_BOARD',
    disabled: false,
    permissionsMode: PermissionsMode.Every,
    permission: STAFFING_BOARD_PAGE_PERMISSIONS,
  },
  {
    icon: 'work',
    path: REQUISITIONS.root,
    name: 'REQUISITIONS',
    disabled: false,
    permission: REQUISITIONS_PERMISSIONS.readByFilter,
  },
  {
    icon: 'work',
    path: CANDIDATES.root,
    name: 'CANDIDATES',
    disabled: false,
    permission: CANDIDATES_PERMISSIONS.readByFilter,
  },
  {
    icon: 'work',
    path: POSITIONS.root,
    name: 'POSITIONS',
    disabled: false,
    permission: POSITIONS_PERMISSIONS.readByFilter,
  },
  {
    icon: 'work',
    path: INTERVIEWS.root,
    name: 'INTERVIEWS',
    disabled: false,
    permission: INTERVIEWS_PERMISSIONS.readByFilter,
    checkVisibility: (injector: EnvironmentInjector) =>
      injector.runInContext(() =>
        inject(AuthorizationService).hasRole(RoleEnum.Interviewer),
      ),
  },
];

export const adminSettingsRoute: IRoute[] = [
  {
    icon: 'account_circle',
    path: ADMIN.users,
    name: 'USERS',
    disabled: false,
    permission: USER_PERMISSIONS.read,
  },
  {
    icon: 'admin_panel_settings',
    path: ADMIN.roles,
    name: 'ROLES',
    disabled: false,
    permission: ROLES_PERMISSIONS.read,
  },
  {
    path: HISTORY_LOGS.root,
    name: 'HISTORY_LOGS',
    disabled: false,
    permission: HISTORY_LOGS_PERMISSIONS.readByFilter,
  },
  {
    icon: 'apps',
    path: REFERENCE_LIST.root,
    name: 'REFERENCE_LIST',
    disabled: false,
    permission: Object.values(REFERENCES_PERMISSIONS),
    children: [
      {
        icon: 'language',
        path: REFERENCE_LIST.englishLevels,
        name: 'ENGLISH_LEVELS',
        disabled: false,
        permission: REFERENCES_PERMISSIONS.englishLevels,
      },
      {
        icon: 'lightbulb_circle',
        path: REFERENCE_LIST.guilds,
        name: 'GUILDS',
        disabled: false,
        permission: REFERENCES_PERMISSIONS.guilds,
      },
      {
        icon: 'work',
        path: REFERENCE_LIST.jobTitles,
        name: 'JOB_TITLES',
        disabled: false,
        permission: REFERENCES_PERMISSIONS.jobTitles,
      },
      {
        icon: 'wysiwyg',
        path: REFERENCE_LIST.skills,
        name: 'SKILLS',
        disabled: false,
        permission: REFERENCES_PERMISSIONS.skills,
      },
      {
        icon: 'table_rows',
        path: REFERENCE_LIST.skillSets,
        name: 'SKILL_SETS',
        disabled: false,
        permission: REFERENCES_PERMISSIONS.skillSets,
      },
      {
        icon: 'insights',
        path: REFERENCE_LIST.levels,
        name: 'SKILL_LEVELS',
        disabled: false,
        permission: REFERENCES_PERMISSIONS.levels,
      },
      {
        icon: 'public',
        path: REFERENCE_LIST.regions,
        name: 'RATE_REGIONS',
        disabled: false,
        permission: REFERENCES_PERMISSIONS.rateRegions,
      },
      {
        icon: 'public',
        path: REFERENCE_LIST.geographicalRegions,
        name: 'REGIONS',
        disabled: false,
        permission: REFERENCES_PERMISSIONS.geographicalRegions,
      },
      {
        icon: 'public',
        path: REFERENCE_LIST.countries,
        name: 'COUNTRIES',
        disabled: false,
        permission: REFERENCES_PERMISSIONS.countries,
      },
      {
        icon: 'tornado',
        path: REFERENCE_LIST.seniorities,
        name: 'SENIORITIES',
        disabled: false,
        permission: REFERENCES_PERMISSIONS.seniorities,
      },
      {
        icon: 'public',
        path: REFERENCE_LIST.timeZones,
        name: 'TIME_ZONES',
        disabled: false,
        permission: REFERENCES_PERMISSIONS.timeZones,
      },
      {
        icon: 'public',
        path: REFERENCE_LIST.workStatuses,
        name: 'WORK_STATUSES',
        disabled: false,
        permission: REFERENCES_PERMISSIONS.timeZones,
      },
      {
        icon: 'public',
        path: REFERENCE_LIST.rejectionReasons,
        name: 'REJECTION_REASONS',
        disabled: false,
        permission: REFERENCES_PERMISSIONS.rejectionReasons,
      },
      {
        icon: 'public',
        path: REFERENCE_LIST.withdrawalReasons,
        name: 'WITHDRAWAL_REASONS',
        disabled: false,
        permission: REFERENCES_PERMISSIONS.withdrawalReasons,
      },
      {
        icon: 'public',
        path: REFERENCE_LIST.onboardingChecklist,
        name: 'ONBOARDING_CHECKLIST',
        disabled: false,
        permission: REFERENCES_PERMISSIONS.onboardingChecklist,
      },
      {
        icon: 'public',
        path: REFERENCE_LIST.industries,
        name: 'INDUSTRIES',
        disabled: false,
        permission: REFERENCES_PERMISSIONS.industries,
      },
    ],
  },
];

export const routes: IRoute[] = [
  {
    icon: 'dashboard',
    path: DASHBOARD.root,
    name: 'DASHBOARD',
    disabled: false,
    permissionsMode: PermissionsMode.Every,
    permission: DASHBOARD_PAGE_PERMISSIONS,
  },
  {
    icon: 'work',
    path: PERSONAL_PROFILE.root,
    name: 'PERSONAL_PROFILE',
    disabled: false,
    permission: EMPLOYEES_PERMISSIONS.readPersonalProfile,
    checkVisibility: (injector: EnvironmentInjector) =>
      runInInjectionContext(injector, () => {
        const authorizationService = inject(AuthorizationService);
        return (
          authorizationService.hasAttachedEmployee &&
          authorizationService.hasRole(RoleEnum.Resource)
        );
      }),
  },
  {
    icon: 'work',
    path: INTERVIEWS.root,
    name: 'INTERVIEWS',
    disabled: false,
    permission: INTERVIEWS_PERMISSIONS.readByFilter,
    checkVisibility: (injector: EnvironmentInjector) =>
      runInInjectionContext(
        injector,
        () => inject(AuthorizationService).isInterviewerOrEmployee,
      ),
  },
  {
    icon: 'account_balance',
    path: ACCOUNTS.root,
    name: 'ACCOUNTS',
    disabled: false,
    permission: ACCOUNTS_PERMISSIONS.readByFilter,
  },
  {
    icon: 'work',
    path: PROJECTS.root,
    name: 'PROJECTS',
    disabled: false,
    permission: PROJECTS_PERMISSIONS.readByFilter,
  },
  {
    icon: 'work',
    path: PLACEMENTS.root,
    name: 'PLACEMENTS',
    disabled: false,
    permission: PLACEMENTS_PERMISSIONS.readByFilter,
  },
  {
    path: VENDORS.root,
    name: 'VENDORS',
    disabled: false,
    permission: VENDORS_PERMISSIONS.readByFilter,
  },
  {
    icon: 'people',
    path: EMPLOYEES.root,
    name: 'EMPLOYEES',
    disabled: false,
    permission: EMPLOYEES_PERMISSIONS.readByFilter,
  },
  {
    icon: 'apps',
    path: ONBOARDING.root,
    name: 'ONBOARDING',
    disabled: false,
    permission: ONBOARDING_PAGE_PERMISSIONS,
    children: onboardingRoute,
    checkVisibility: (injector: EnvironmentInjector) =>
      runInInjectionContext(
        injector,
        () => !inject(AuthorizationService).isInterviewerOrEmployee,
      ),
  },
  {
    path: REPORTS.root,
    name: 'REPORTS',
    disabled: false,
    permission: REPORTS_PERMISSIONS.read,
  },
  {
    icon: 'admin_panel_settings',
    path: ADMIN.root,
    name: 'SETTINGS',
    disabled: false,
    permission: SETTINGS_PAGE_PERMISSIONS,
    children: adminSettingsRoute,
  },
  {
    path: FINANCE.root,
    name: 'FINANCE',
    disabled: false,
    permission: FINANCE_PERMISSIONS,
    children: financeRoute,
  },
  {
    path: PERSONAL_TIME_SHEETS.root,
    name: 'PERSONAL_TIME_SHEETS',
    disabled: false,
    permission: KANDA_TIME_SHEET_PERMISSIONS.readPersonalTimeSheets,
    checkVisibility: (injector: EnvironmentInjector) =>
      runInInjectionContext(injector, () => {
        const authorizationService = inject(AuthorizationService);
        return (
          authorizationService.hasAttachedEmployee &&
          authorizationService.hasRole(RoleEnum.Resource)
        );
      }),
  },
  {
    path: KANDA_CA.root,
    name: 'KANDA_CA',
    disabled: false,
    permission: KANDA_CA_PERMISSIONS,
    children: kandaCARoute,
  },
];
